import React, { useState, useEffect } from "react";

import {
  Text,
  Flex,
  IconButton,
  Box,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useDisclosure,
  Button,
  Input,
  SimpleGrid,
  GridItem,
} from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { updateName2, updateOrder } from "../../features/order/orderSlice";
import {
  castMyUpdatedData,
  castUpdatedData,
} from "../../features/order/orderSlice";
function EditOrderName2({ id }) {
  const dispatch = useDispatch();
  const { user } = useSelector((store) => store.user);
  const { orderAnswers } = useSelector((store) => store.products);
  useEffect(() => {
    dispatch(castMyUpdatedData());
    dispatch(castUpdatedData());
  }, [dispatch]);
  const { theme, btnColorScheme } = useSelector((store) => store.theme);
  let { updatedData, myUpdatedData } = useSelector((store) => store.order);
  let order =
    user.role === "Clinic"
      ? Object.assign({}, myUpdatedData)
      : Object.assign({}, updatedData);

  const [value, setValue] = useState(order.order.patientInfo.lastname);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();

  const note = `Change the patient name from ${order.order.patientInfo.lastname} To ${value}`;
  const submitHandler = () => {
    if (value !== order.order.patientInfo.lastname) {
      const data = { id: id, order: Object.assign({}, order) };
      // Make a deep copy of the order object and its nested properties
      data.order = JSON.parse(JSON.stringify(order));

      // Update the patient name in the copied object
      data.order.order.patientInfo.lastname = value;

      // Optionally, update the history or other properties if needed
      if (user.role !== "Clinic") data.order.history.updateDescription = note;
      data.order.order.answerIds = [...orderAnswers];
      dispatch(updateOrder(data));

      dispatch(updateName2({ name: value, description: note }));
    }
    onClose();
  };
  const { orderLabels, buttonsText, diaglogText } = useSelector(
    (store) => store.lang
  );
  return (
    <Box rounded={"md"}>
      <SimpleGrid
        columns={10}
        p={"1rem"}
        alignItems={"center"}
        boxShadow={"sm"}
      >
        <GridItem colSpan={4}>
          <Text fontWeight={"semibold"} fontSize={"1rem"}>
            {orderLabels.lastName}
          </Text>
        </GridItem>
        <GridItem colSpan={4}>
          <Text fontWeight={"semibold"} fontSize={"1rem"}>
            {order.order.patientInfo.lastName}
          </Text>
        </GridItem>
        <GridItem colSpan={2} justifySelf={"end"}>
          <IconButton
            onClick={onOpen}
            fontSize={"md"}
            variant={"solid"}
            rounded={"md"}
            icon={<EditIcon />}
            colorScheme={btnColorScheme[theme]}
            _hover={{ fontSize: "sm" }}
          />
        </GridItem>
        <AlertDialog
          motionPreset="slideInBottom"
          leastDestructiveRef={cancelRef}
          onClose={onClose}
          isOpen={isOpen}
          isCentered
        >
          <AlertDialogOverlay />

          <AlertDialogContent>
            <AlertDialogHeader>{diaglogText.Discard}</AlertDialogHeader>
            <AlertDialogCloseButton />
            <AlertDialogBody>
              <Flex direction={"column"} gap={"1rem"} fontSize={"large"}>
                <Input
                  p={".5rem"}
                  value={value}
                  onChange={(e) => {
                    setValue(e.target.value);
                  }}
                />
                <Text>
                  {diaglogText.lastNamemsg} {order.order.patientInfo.lastName}{" "}
                  {diaglogText.to} {value}
                </Text>
              </Flex>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={() => {
                  setValue(order.order.patientInfo.lastname);
                  onClose();
                }}
              >
                {buttonsText.No}
              </Button>
              <Button colorScheme="red" ml={3} onClick={submitHandler}>
                {buttonsText.yes}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </SimpleGrid>
    </Box>
  );
}

export default EditOrderName2;
