import { Text, Flex } from "@chakra-ui/react";
import React from "react";

function Message({ userName, sender, message, time, icon }) {
  return (
    <Text
      p={"2"}
      my={".5rem"}
      borderRadius={sender ? "1rem 1rem 0 1rem" : "1rem 1rem 1rem 0"}
      bgSize={"cover"}
      width={"fit-content"}
      color={sender ? "black" : "black"}
      fontSize={"1rem"}
      maxW={"80%"}
      alignSelf={sender ? "end" : "start"}
      bgColor={sender ? "#eadef8" : "white"}
      mx={"1rem"}
      boxShadow={"sm"}
    >
      <Text
        letterSpacing={"1.1"}
        fontSize={".9rem"}
        fontWeight={"semibold"}
        color={sender ? "pink.700" : "yellow.700"}
      >
        {userName}
      </Text>
      {message}

      <Flex align="center" justify="flex-end">
        <Text
          mt=".2rem"
          color="#40484D"
          fontSize=".6rem"
          textAlign="right"
          w="100%"
        >
          {time}
        </Text>
        {icon}
      </Flex>
    </Text>
  );
}

export default Message;
